const btnFormNonValue = function () {
    $(document).on("click", ".js_good_id", function (e) {
        var clickedElement = $(this);
        var dataId = clickedElement.attr("data-id");
        var dataName = clickedElement.attr("data-name");
        var dataModal = clickedElement.attr("data-modal");
        var dataHref = clickedElement.attr("data-href");
        var dataValue = clickedElement.attr("data-value");

        if (dataValue !== null) {
            var inputValue = $("#" + dataModal).find(".js_input_value");
            inputValue.val(dataValue);
        }

        var inputId = $("#" + dataModal).find(".js_input_id");
        inputId.val(dataId);

        var modalName = $("#" + dataModal).find(".js_modal_name");
        modalName.text(dataName);

        if (dataHref) {
            var inputUrl = $("#" + dataModal).find(".js_input_url");
            inputUrl.val(dataHref);
        }

        $("#" + dataModal).addClass("js_active");

        if (dataModal == 'modal_one_click' || dataModal == 'modal_price_req' || dataModal == 'modal_good_req') {
            if (clickedElement.hasClass('js__gtm_btn')) {
                var dataGtmItemList = clickedElement.attr("data-gtm_item_list");
                var dataGtmIndex = clickedElement.attr("data-gtm_item");
            } else {
                var dataGtmItemList = clickedElement.closest('.js_good_tile').attr("data-gtm_item_list");
                var dataGtmIndex = clickedElement.closest('.js_good_tile').attr("data-gtm_item");
            }

            $(`#${dataModal} .js_form_btn`).attr({
                'data-gtm_item_list': dataGtmItemList,
                'data-gtm_item': dataGtmIndex,
            });
        }
    });

}

const btnFormAddCart = function () {

    function loadTopCart() {
        $.post("load-top-cart", {
            lang: lang
        }, (function (e) {
            "ok" == e.status && $(".js_header_cart").empty().append(e.html)
        }))
    }

    function loadMainCart() {
        $.post("load-main-cart", {
            lang: lang
        }, (function (e) {
            "ok" == e.status && $(".js_main_cart").empty().append(e.html);
            goodsDeclination();
        }))
    }

    $(document).on("click", ".js_btn_cart", (function (e) {
        var productNowBuy = $(this);

        if (productNowBuy.hasClass('js__gtm_btn')) {
            var dataGtmItemList = productNowBuy.attr("data-gtm_item_list");
            var dataGtmIndex = productNowBuy.attr("data-gtm_item");
        } else {
            var dataGtmItemList = productNowBuy.closest('.js_good_tile').attr("data-gtm_item_list");
            var dataGtmIndex = productNowBuy.closest('.js_good_tile').attr("data-gtm_item");
        }

        $.post("add-to-cart", {
            good_id: productNowBuy.attr("data-id"),
            value: productNowBuy.attr("data-value"),
            valueName: productNowBuy.attr("data-title"),
            lang: lang
        }, (function (e) {
            if ("ok" == e.status) {
                $("#cart_modal_content").empty().append(e.html);
                $("#modal_add_good_to_cart").addClass("js_active");
                $('#modal_add_good_to_cart .lazyload').attr('src', $('#modal_add_good_to_cart .lazyload').attr('data-src'))
                productNowBuy.removeClass("to_cart").addClass("in_cart");
                $('.block-btn-wrap-good button[data-text-result]').removeClass("to_cart").addClass("in_cart");
                loadTopCart();

                if (gtmActive != "" && gtmActive) {
                    $.post(routes.getGTMLocal, {
                        'id': productNowBuy.attr("data-id"),
                    }, function (data) {
                        if (data.status == 'ok') {
                            let good_object = JSON.parse(localStorage.getItem(data.data.id));
                            if (!good_object) {
                                const dataGtm = [{
                                    item_name: data.data.name,
                                    item_id: String(data.data.id),
                                    price: Number(data.data.price_gtm),
                                    item_brand: data.data.item_brand,
                                    item_category: data.data.cat1 != "" ? data.data.cat1 : null,
                                    item_category2: data.data.cat2 != "" ? data.data.cat2 : null,
                                    item_variant: data.data.gtm_remains,
                                    item_list_name: dataGtmItemList,
                                    index: Number(dataGtmIndex),
                                    quantity: 1,
                                }, { quantity: 1 }];
                                dataLayer.push({
                                    ecommerce: null
                                });
                                dataLayer.push({
                                    event: "add_to_cart",
                                    label: "main",
                                    ecommerce: {
                                        items: [dataGtm[0]]
                                    }
                                });

                                localStorage.setItem(data.data.id, JSON.stringify(dataGtm));
                            }
                        }
                    });
                }

            }

            if (is_facebook_pixel == 1 && parseInt(e.return_data.price) > 0) {
                let fbq_data = {
                    value: e.return_data.price,
                    currency: 'UAH',
                    content_ids: e.return_data.good_id,
                    content_type: 'product',
                }
                fbq('track', 'AddToCart', fbq_data);
            }
        }))
    }))

    $(document).on("click", ".js_amount_down", function () {
        var inputElement = $(this).parent().find("input");
        var productID = inputElement.attr("data-id");
        var currentValue = parseInt(inputElement.val()) - 1;

        currentValue = currentValue < 1 ? 1 : currentValue;

        $('.js_amount_count[data-id="' + productID + '"]').val(currentValue).change();

        $.post("update-cart", {
            id: productID,
            count: -1,
            lang: lang
        }, function (response) {
            if (response.status === "ok") {
                $(".js_modal_good_subtotal").html(response.new_sum);
                $('.js_cart_good_subtotal[data-id="' + productID + '"]').html(response.new_sum);
                loadTopCart();

                let good_object = JSON.parse(localStorage.getItem(productID));
                good_object[1].quantity = Number(currentValue);

                localStorage.setItem(productID, JSON.stringify(good_object));

                if ($(".js_main_cart").length > 0) {
                    loadMainCart();
                }
            }
        });
    });

    $(document).on("click", ".js_amount_up", function () {
        var inputElement = $(this).parent().find("input");
        var productID = inputElement.attr("data-id");
        var currentValue = parseInt(inputElement.val()) + 1;

        $('.js_amount_count[data-id="' + productID + '"]').val(currentValue).change();

        $.post("update-cart", {
            id: productID,
            count: 1,
            lang: lang
        }, function (response) {
            if (response.status === "ok") {
                $(".js_modal_good_subtotal").html(response.new_sum);
                $('.js_cart_good_subtotal[data-id="' + productID + '"]').html(response.new_sum);
                loadTopCart();

                if (gtmActive != "" && gtmActive) {
                    let good_object = JSON.parse(localStorage.getItem(productID));
                    good_object[1].quantity = Number(currentValue);

                    dataLayer.push({
                        ecommerce: null
                    });
                    dataLayer.push({
                        event: "add_to_cart",
                        label: "main",
                        ecommerce: {
                            items: [good_object[0]]
                        }
                    });

                    localStorage.setItem(productID, JSON.stringify(good_object));
                }

                if ($(".js_main_cart").length > 0) {
                    loadMainCart();
                }
            }
        });
    });


    // Обработчик клика на кнопке удаления товара из корзины
    $(document).on("click", ".js_good_delete", function () {
        var deleteButton = $(this);
        var productID = deleteButton.attr("data-id");

        $.post("delete-cart", {
            id: productID,
            lang: lang
        }, function (response) {
            if ("ok" == response.status) {
                deleteButton.closest(".cart_page_item").remove();
                loadTopCart();
                loadMainCart();

                localStorage.removeItem(productID);

                if ($(".cart_page_item").length < 1) {
                    window.location.href = main_market_cat;
                }
            }
        });
    });


    $(document).on("click", ".js_get_variants", function (e) {
        var productID = $(this).attr("data-id");
        var typeCar = $(this).attr("data-modal");

        if ($(this).hasClass('js__gtm_btn')) {
            var dataGtmItemList = $(this).attr("data-gtm_item_list");
            var dataGtmIndex = $(this).attr("data-gtm_item");
        } else {
            var dataGtmItemList = $(this).closest('.js_good_tile').attr("data-gtm_item_list");
            var dataGtmIndex = $(this).closest('.js_good_tile').attr("data-gtm_item");
        }

        $.post(routes.getVariant, {
            good_id: productID,
            lang: lang,
            type_car: typeCar,
            gtm_index: dataGtmIndex,
            gtm_ltem_list: dataGtmItemList,
        }, function (response) {
            if ("ok" == response.status) {
                const modalHeader = $("#modal_good_size").find(".modal__header");
                const titleSize = modalHeader.attr('data-text');
                modalHeader.text(`${titleSize} ${response.title_variant}`);

                $("#modal_good_size").find(".modal__body").empty().append(response.html);

                $("#modal_good_size").addClass("js_active");
            }
        });
    });

}

const uploadingProductData = function () {
    $(document).on("mouseover", ".good_tile__block", (function (e) {
        let href = $(this).attr('data-href');
        let image = $(this).attr('data-image');
        $(this).closest('.good_tile__link').attr('href', href);
        if (image != '') {
            $(this).closest('.good_tile__link').find('.good_tile_img').attr('src', image);
        }
    }));
}

const editWishlist = function () {
    $(document).on("click", ".js_btn_like", (function (e) {
        e.stopPropagation();
        e.preventDefault();

        var element = $(this),
            id = element.attr("data-id");

        if (element.hasClass('js__gtm_btn')) {
            var dataGtmItemList = element.attr("data-gtm_item_list");
            var dataGtmIndex = element.attr("data-gtm_item");
        } else {
            var dataGtmItemList = element.closest('.js_good_tile').attr("data-gtm_item_list");
            var dataGtmIndex = element.closest('.js_good_tile').attr("data-gtm_item");
        }


        element.parents(".js_parent_like").addClass("js_show");
        if (element.hasClass("to_wishlist")) {
            $.post(routes.editWishlist, {
                id: id,
                action: "add"
            }, (function (e) {
                if ("ok" == e.status) {
                    element.removeClass("to_wishlist");
                    element.addClass("in_wishlist");
                    editWishIconGlobal();

                    if (gtmActive != "" && gtmActive) {
                        $.post(routes.getGTMLocal, {
                            'id': id,
                        }, function (data) {
                            if (data.status == 'ok') {
                                dataLayer.push({
                                    ecommerce: null
                                });

                                dataLayer.push({
                                    event: "add_to_wishlist",
                                    ecommerce: {
                                        items: [{
                                            item_name: data.data.name,
                                            item_id: String(data.data.id),
                                            price: Number(data.data.price_gtm),
                                            item_brand: data.data.item_brand,
                                            item_category: data.data.cat1 != "" ? data.data.cat1 : null,
                                            item_category2: data.data.cat2 != "" ? data.data.cat2 : null,
                                            item_variant: data.data.gtm_remains,
                                            item_list_name: dataGtmItemList,
                                            index: Number(dataGtmIndex),
                                        }]
                                    }
                                });
                            }
                        });
                    }
                }
            }))
        } else {
            if (element.hasClass("in_wishlist")) {
                $.post(routes.editWishlist, {
                    id: id,
                    action: "remove"
                }, (function (e) {
                    if ("ok" == e.status) {
                        element.removeClass("in_wishlist");
                        element.addClass("to_wishlist")
                        element.parents(".js_parent_like").removeClass("js_show")
                        editWishIconGlobal()
                    }
                }))
            }
        }
    }))
}

const editWishIconGlobal = function () {
    $.post(routes.loadWishIcon, {
        lang: lang
    }, (function (e) {
        "ok" == e.status && $(".js_header_like").empty().append(e.html)
    }))
}

const submittingReviewAndFile = function () {
    var Main = (function ($) {
        var labelText = $('.form__file-lt:lt(1)').text();
        $(function () {
            Main.init();
        })
        return {
            init: function () {
                var self = this;
                self.inputHadler();
                self.valid();
                self.formValidates();
                self.fileInputs();
            },
            fileInputs: function () {
                $('.js_add_file').click(function (e) {
                    e.preventDefault();
                    var nb
                    if ($('.js_item_file_reviews').length == 0) {
                        nb = $('.js_item_file_reviews').length + 1;
                    } else {
                        nb = + $('.js_item_file_reviews').attr('data-number') + 1;
                    }
                    if ($('.js_item_file_reviews').length < 2) {
                        var sizeError = document.querySelector('.form__size-error-js'),
                            extensionError = document.querySelector('.form__extention-err-js');
                        var whiteList = [
                            "text/plain",
                            "application/msword",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "application/vnd.oasis.opendocument.text",
                            "application/rtf",
                            "application/pdf",
                            "application/octet-stream",
                            "image/jpeg",
                            "image/png",
                            "application/vnd.ms-excel",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            "application/vnd.oasis.opendocument.spreadsheet",
                        ];
                        var $input = $('<input type="file" value="" name="photo_' + nb + '" accept="image/*" class="input-hidden input-file-js"/>');
                        var form = e.target.closest('.form-reviews')
                        $input.on('change', function (evt) {
                            if (this.files.length) {
                                for (var i = 0; i < this.files.length; i++) {
                                    if ((whiteList.indexOf(this.files[i].type) != -1 && this.files[i].size < 12000000) || ((this.files[i].name).indexOf('.dwg') != -1 && this.files[i].size < 12000000)) {
                                        sizeError.classList.remove('js_show');
                                        extensionError.classList.remove('js_show');
                                        var f = evt.target.files[0];
                                        console.log($(this))
                                        $(form).append($(this));
                                        $('ul.js_list_file_reviews').append('<li class="js_item_file_reviews item_file_reviews" data-number="' + nb + '" data-item="photo_' + nb + '"><span class="name">' + f.name + '</span><span class="js_item_file_delete item_file_delete"></span></li>');
                                        if ($('.js_item_file_reviews').length == 2) {
                                            document.querySelector('.js_add_file').classList.add('js_hide');
                                        }
                                    } else {
                                        sizeError.classList.remove('js_show');
                                        extensionError.classList.remove('js_show');
                                        if (whiteList.indexOf(this.files[i].type) == -1) extensionError.classList.add('js_show');
                                        if (this.files[i].size > 12000000) sizeError.classList.add('js_show');
                                        console.log(this.files[i].size);
                                        console.log(whiteList.indexOf(this.files[i].type));
                                        $(this).value = '';
                                        $(this).closest('.form__file-wr').find('.form__file-remove').addClass('js_hide');
                                    }
                                }
                            }
                        });
                        $input.hide();
                        $input.trigger('click');
                    } else {
                        $(this).classList.add('js_hide');
                    }
                });
            },
            valid: function (data) {
                function sendTextFields(item, formdata, file = '') {
                    var dataForm;
                    if (file != '') {
                        dataForm = {
                            data: formdata,
                            file: file,
                            subj: "review",
                        }
                    } else {
                        dataForm = {
                            data: formdata,
                            subj: "review",
                        };
                    };
                    $.ajax({
                        url: routes.postSend,
                        type: 'POST',
                        data: dataForm,
                        success: function (data) {
                            $(item)[0].reset();
                            if (document.querySelectorAll('.input-file-js') != null) {
                                $('.js_add_file').removeClass('js_hide');
                                $('.js_list_file_reviews').empty();
                                $('.input-file-js').remove();
                            };
                            setTimeout(function () {
                                $('.ajax-loader').addClass('hidden');
                                $('#form-reviews').find(".form-input").val("").blur();
                                $('.span__error').css('display', 'none');
                                $(".review-form-js").find('.review-form__success').slideDown(300);
                            }, 100);
                            setTimeout(function () {
                                $(".review-form-js").find('.review-form__success').slideUp(300);
                            }, 3000);
                        }
                    })
                };
                var item = data;
                $(item).validate({
                    errorElement: 'span',
                    errorClass: 'span__error',
                    ignoreTitle: true,
                    errorPlacement: function (error, element) {
                        error.appendTo($(element).parents('.input-route-wrap'));
                    },
                    highlight: function (element) {
                        $(element).parents('.input-route-wrap').addClass("forms__error");
                    },
                    unhighlight: function (element) {
                        $(element).parents('.input-route-wrap').removeClass("forms__error");
                    },
                    submitHandler: function (form) {
                        var csrf_token = $('meta[name="csrf-token"]').attr('content');
                        var formdata = $(item).serialize();
                        if (document.querySelectorAll('.input-file-js') != null && document.querySelectorAll('.input-file-js').length != 0) {
                            var inputs = $('.input-file-js');
                            let $data = {};
                            for (let i = 0; i < inputs.length; i++) {
                                let element = inputs[i];
                                let next_el = (inputs[i + 1]) ? (inputs[i + 1]) : null;
                                if (element != null && element.files.length) {
                                    let reader = new FileReader();
                                    reader.onload = function () {
                                        let key = 'file_' + i;
                                        let value = reader.result;
                                        $data[key] = value;
                                        if (next_el == null || !next_el.files.length) {
                                            setTimeout(function () {
                                                $.ajax({
                                                    type: 'POST',
                                                    url: routes.postLoadFile,
                                                    data: {
                                                        "_token": csrf_token,
                                                        "data": $data,
                                                    },
                                                    success: function (response) {
                                                        var uploadedFile = response.file_name;

                                                        sendTextFields(item, formdata, uploadedFile);
                                                    },
                                                    error: function (response) { },
                                                });
                                            }, 100);
                                        }
                                    };
                                    reader.readAsDataURL(inputs[i].files[0]);
                                }
                            }
                        } else {
                            sendTextFields(item, formdata);
                        }
                    }
                });
            },
            formValidates: function () {
                var button = document.getElementById('reviews-btn'),
                    form = document.getElementsByClassName('review-form-js');
                function submitForm(event) {
                    event.preventDefault();
                    event.stopPropagation();

                    Main.valid(form);
                    $(form).submit();

                    return form;
                }
                if (button != null) {
                    button.addEventListener('click', submitForm, true);
                }


            },
            inputHadler: function () {
                $(document).on('click', '.js_item_file_delete', function (e) {
                    var item_file = $(this).parent('.js_item_file_reviews').attr('data-item');
                    $(this).parent('.js_item_file_reviews').remove();
                    $('.input-file-js[name=' + item_file + ']').remove();
                    if ($('.js_item_file_reviews').length < 2) {
                        $('.js_add_file').removeClass('js_hide');
                    }
                });
            }
        }
    })($);
}

const submittingFormsValidationCheck = function () {

    $(".input__input.phone").mask("+38 (999) 999-99-99");

    $("#checkbox_feedback").change((function () {
        this.checked ? ($("#do_feedback").removeClass("js_disabled"), $(".js_text_error").addClass("js_hide"), $(".js_text_yes").removeClass("js_hide")) : $("#do_feedback").addClass("js_disabled")
    }));

    $("#do_feedback").click((function (e) {
        $(this).hasClass("js_disabled") ? ($(".js_text_yes").addClass("js_hide"), $(".js_text_error").removeClass("js_hide")) : ($("#feedback").submit(), $(".js_text_error").addClass("js_hide"), $(".js_text_yes").removeClass("js_hide"))
    }));

    $("#feedback").validate({
        errorElement: "span",
        errorClass: "input__error",
        ignoreTitle: !0,
        errorPlacement: function (e, s) {
            e.appendTo($(s).parents(".js_input_block"))
        },
        highlight: function (e) {
            $(e).parents(".js_input_wrapper").addClass("forms__error")
        },
        unhighlight: function (e) {
            $(e).parents(".js_input_wrapper").removeClass("forms__error")
        },
        submitHandler: function (e) {
            var s = $('meta[name="csrf-token"]').attr("content"),
                t = $("#feedback").serialize();


            var phoneInput = $(e).find(".phone");
            if (phoneInput.length > 0) {
                var phoneNumber = phoneInput.val();
                phoneNumber = phoneNumber.replace(/\D/g, "");
                if (phoneNumber.length !== 12) {
                    phoneInput.closest('.js_input_wrapper').addClass('forms__error error_num_intut');
                    return;
                }
            }

            $("#feedback")[0].reset(), $.ajax({
                url: routes.postSend,
                type: "POST",
                data: {
                    _token: s,
                    data: t,
                    subj: "feedback",
                    send_type: "not_modal",
                    form_id: "none"
                },
                success: function (e) {
                    $("#success_feedback").removeClass("js_hide"), $(".input__input").next(".input__placeholder").css({
                        top: "11px"
                    }), $(".input__input").removeClass("active_input"), setTimeout((function () {
                        $("#success_feedback").addClass("js_hide")
                    }), 4e3)
                }
            })
        }
    });

    $(".js_form_btn").click(function (e) {
        var clickedButton = $(this);
        var formId = clickedButton.attr("data-id");

        if (formId == 'market_order_one_click' || formId == 'good_request' || formId == 'price_request') {
            var dataGtmItemList = clickedButton.attr("data-gtm_item_list");
            var dataGtmIndex = clickedButton.attr("data-gtm_item");
        }

        var form = $('.js_form[id="' + formId + '"]');

        form.validate({
            errorElement: "span",
            errorClass: "input__error",
            ignoreTitle: true,
            errorPlacement: function (error, element) {
                error.appendTo(element.parents(".js_input_block"));
            },
            highlight: function (element) {
                $(element).parents(".js_input_wrapper").addClass("forms__error");
            },
            unhighlight: function (element) {
                $(element).parents(".js_input_wrapper").removeClass("forms__error");
            },
            submitHandler: function (form) {
                var subject = form.getAttribute("data-subj");
                var sendType = form.getAttribute("data-modal");
                var formId = form.getAttribute("id");
                var csrfToken = $('meta[name="csrf-token"]').attr("content");
                var formData = $(form).serialize();

                var phoneInput = $(form).find(".phone");
                if (phoneInput.length > 0) {
                    var phoneNumber = phoneInput.val().replace(/\D/g, "");
                    if (phoneNumber.length !== 12) {
                        phoneInput.closest('.js_input_wrapper').addClass('forms__error error_num_intut');
                        return;
                    }
                }

                $(form)[0].reset();

                $.ajax({
                    url: routes.postSend,
                    type: "POST",
                    data: {
                        _token: csrfToken,
                        data: formData,
                        subj: subject,
                        send_type: sendType,
                        form_id: formId
                    },
                    success: function (response) {
                        if (response.form_id == 'market_order_one_click') {

                            var orderId = response.order_id;

                            if (gtmActive != "" && gtmActive) {

                                $.post(routes.getGTMLocal, {
                                    'id': $('#market_order_one_click input[name="good_id"]').val(),
                                }, function (data) {
                                    if (data.status == 'ok') {
                                        dataLayer.push({ ecommerce: null });
                                        dataLayer.push({
                                            event: "purchase",
                                            ecommerce: {
                                                transaction_id: orderId,
                                                affiliation: "by_one_click",
                                                value: data.data.price_gtm,
                                                currency: "UAH",
                                                items: [{
                                                    item_name: data.data.name,
                                                    item_id: String(data.data.id),
                                                    price: Number(data.data.price_gtm),
                                                    item_brand: data.data.item_brand,
                                                    item_category: data.data.cat1 != "" ? data.data.cat1 : null,
                                                    item_category2: data.data.cat2 != "" ? data.data.cat2 : null,
                                                    item_variant: data.data.gtm_remains,
                                                    item_list_name: dataGtmItemList,
                                                    index: Number(dataGtmIndex),
                                                    quantity: 1
                                                }]
                                            }
                                        });
                                    }
                                });
                            }

                            if (is_facebook_pixel == 1) {
                                let fbqData = {
                                    value: response.return_data.price,
                                    currency: 'UAH',
                                    content_ids: response.return_data.good_id,
                                    content_type: 'product',
                                };
                                fbq('track', 'Purchase', fbqData);
                            }
                        }

                        if (gtmActive != "" && gtmActive) {
                            if (response.form_id == 'good_request') {
                                dataLayer.push({ ecommerce: null });

                                $.post(routes.getGTMLocal, {
                                    'id': $('#good_request input[name="good_id"]').val(),
                                }, function (data) {
                                    if (data.status == 'ok') {
                                        dataLayer.push({
                                            ecommerce: null
                                        });

                                        dataLayer.push({
                                            event: "good_request",
                                            ecommerce: {
                                                items: [{
                                                    item_name: data.data.name,
                                                    item_id: String(data.data.id),
                                                    price: Number(data.data.price_gtm),
                                                    item_brand: data.data.item_brand,
                                                    item_category: data.data.cat1 != "" ? data.data.cat1 : null,
                                                    item_category2: data.data.cat2 != "" ? data.data.cat2 : null,
                                                    item_variant: data.data.gtm_remains,
                                                    item_list_name: dataGtmItemList,
                                                    index: Number(dataGtmIndex),
                                                }]
                                            }
                                        });
                                    }
                                });
                            }

                            if (response.form_id == 'price_request') {
                                dataLayer.push({ ecommerce: null });

                                $.post(routes.getGTMLocal, {
                                    'id': $('#price_request input[name="good_id"]').val(),
                                }, function (data) {
                                    if (data.status == 'ok') {
                                        dataLayer.push({
                                            ecommerce: null
                                        });

                                        dataLayer.push({
                                            event: "price_request",
                                            ecommerce: {
                                                items: [{
                                                    item_name: data.data.name,
                                                    item_id: String(data.data.id),
                                                    price: Number(data.data.price_gtm),
                                                    item_brand: data.data.item_brand,
                                                    item_category: data.data.cat1 != "" ? data.data.cat1 : null,
                                                    item_category2: data.data.cat2 != "" ? data.data.cat2 : null,
                                                    item_variant: data.data.gtm_remains,
                                                    item_list_name: dataGtmItemList,
                                                    index: Number(dataGtmIndex),
                                                }]
                                            }
                                        });
                                    }
                                });
                            }
                        }

                        if (response.form_id == 'callback') {
                            if (is_facebook_pixel == 1) {
                                fbq('track', 'Contact');
                            }
                        }
                        var successMessage = $(".form__success[data-success=" + response.form_id + "]");
                        successMessage.removeClass("js_hide");
                        $(".input__input").next(".input__placeholder").css({
                            top: "14px"
                        });
                        $(".input__input").removeClass("active_input");
                        $(".js_rating_text").addClass("js_hide");
                        setTimeout(function () {
                            successMessage.addClass("js_hide");
                        }, 4000);
                        if ("modal" == response.send_type) {
                            setTimeout(function () {
                                var modal = document.querySelector(".modal.js_active");
                                var html = document.querySelector("html");
                                modal.classList.remove("js_active");
                                html.style.overflow = "";
                            }, 5000);
                        }
                    }
                });
            }
        });
    });


    $('.input__input[type="password"]').each(function () {

        $(this).addClass('view_false');
        $(this).after(`<button type="button" class="js_password_view password_view"><svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 6C8.41454 6 4.25621 8.63041 1.18779 12.9029C0.937404 13.2529 0.937404 13.7419 1.18779 14.092C4.25621 18.3696 8.41454 21 13 21C17.5855 21 21.7438 18.3696 24.8122 14.0971C25.0626 13.7471 25.0626 13.2581 24.8122 12.908C21.7438 8.63041 17.5855 6 13 6ZM13.1767 16.3415C11.537 16.4496 10.182 15.034 10.29 13.3147C10.3734 11.894 11.4732 10.7461 12.8282 10.6534C14.4679 10.5453 15.823 11.9609 15.7149 13.6802C15.6266 15.106 14.5268 16.2539 13.1767 16.3415Z" stroke="#333333" stroke-width="2"/>
        <path class="path_svg" d="M25 1L1 25" stroke="#333333" stroke-width="2" stroke-linecap="round"/>
        </svg></button>`);

        $(this).next().on('click', function () {
            $(this).toggleClass('view');
            if ($(this).hasClass('view')) {
                $(this).closest('.js_input_block')
                    .children('.view_false').attr('type', 'text');
                $(this).find('.path_svg').hide();
            } else {
                $(this).closest('.js_input_block')
                    .children('.view_false').attr('type', 'password');
                $(this).find('.path_svg').show();
            }
        })
    });

    $(".js_do_login_form").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("#login_form").submit();
    });


    $("#login_form").validate({
        errorElement: "span",
        errorClass: "input__error",
        ignoreTitle: true,
        errorPlacement: function (error, element) {
            error.appendTo(element.parents(".js_input_block"));
        },
        highlight: function (element) {
            $(element).parents(".js_input_wrapper").addClass("forms__error");
        },
        unhighlight: function (element) {
            $(element).parents(".js_input_wrapper").removeClass("forms__error");
        },
        submitHandler: function (form) {
            var formData = $(form).serialize();

            $.ajax({
                url: routes.postLogin,
                type: "POST",
                data: {
                    data: formData,
                    lang: lang
                },
                success: function (response) {
                    $(".input__error").css("display", "none");

                    switch (response) {
                        case "wrong_pass":
                            $("#login_form .js_auth_error").slideDown(200);
                            break;
                        case "success":
                            window.location.reload(true);
                            break;
                    }
                }
            });
        }
    });


    $(".js_do_reg_form").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("#reg_form").submit();
    });

    $("#reg_form").validate({
        errorElement: "span",
        errorClass: "input__error",
        ignoreTitle: true,
        errorPlacement: function (error, element) {
            error.appendTo(element.parents(".js_input_block"));
        },
        highlight: function (element) {
            $(element).parents(".js_input_wrapper").addClass("forms__error");
        },
        unhighlight: function (element) {
            $(element).parents(".js_input_wrapper").removeClass("forms__error");
        },
        submitHandler: function (form) {
            var formData = $(form).serialize();
            $.ajax({
                url: routes.postRegister,
                type: "POST",
                data: {
                    data: formData,
                    lang: lang
                },
                success: function (response) {
                    $(".input__error").css("display", "none");

                    switch (response) {
                        case "email":
                            $("#reg_form .js_auth_error").slideDown(200);
                            break;
                        case "deleted":
                            $("#reg_form .js_auth_del").slideDown(200);
                            break;
                        case "success":
                            $("#reg_form .js_reg_success").slideDown(200);

                            if (gtmActive != "" && gtmActive) {
                                dataLayer.push({ ecommerce: null });
                                dataLayer.push({
                                    'event': 'sign_up'
                                });
                            }

                            setTimeout(function () {
                                window.location.reload(true);
                            }, 3000);
                            break;
                    }
                }
            });
        }
    });


    $(".js_do_reset_form").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("#reset_form").submit();
    });

    $("#reset_form").validate({
        errorElement: "span",
        errorClass: "input__error",
        ignoreTitle: true,
        errorPlacement: function (error, element) {
            error.appendTo(element.parents(".js_input_block"));
        },
        highlight: function (element) {
            $(element).parents(".js_input_wrapper").addClass("forms__error");
        },
        unhighlight: function (element) {
            $(element).parents(".js_input_wrapper").removeClass("forms__error");
        },
        submitHandler: function (form) {
            var formData = $(form).serialize();
            $(".ajax-loader").removeClass("hidden");

            $.ajax({
                url: routes.postPassword,
                type: "POST",
                data: {
                    data: formData,
                    lang: lang
                },
                success: function (response) {
                    $(".input__error").css("display", "none");

                    switch (response) {
                        case "no_email":
                            $("#reset_form .js_auth_error").slideDown(200);
                            $("#reset_form .js_auth_restored").slideUp(200);
                            break;
                        case "success":
                            $("#reset_form .js_auth_restored").slideDown(200);
                            $("#reset_form .js_auth_error").slideUp(200);

                            setTimeout(function () {
                                var modal = document.querySelector(".modal.js_active");
                                var html = document.querySelector("html");
                                modal.classList.remove("js_active");
                                html.style.overflow = "";
                            }, 3000);
                            break;
                    }
                }
            });
        }
    });

}

const modalInstructionsBehavior = function () {
    $(document).on("click keydown", ".js_modal_btn", handleModalButtonClick);
    $(document).on("click", ".js_modal_overlay", handleOverlayClick);
    $(document).on("click", ".js_modal_close", handleCloseClick);
    $(document).on("keydown", handleEscapeKey);

    function handleModalButtonClick(event) {
        const clickedElement = $(event.target);
        const htmlElement = $("html");

        if (clickedElement.closest(".js_modal_btn").length) {
            const modalButton = clickedElement.closest(".js_modal_btn");

            if (modalButton.data("close")) {
                const modalToCloseId = modalButton.data("close");
                $("#" + modalToCloseId).removeClass("js_active");
            }

            if (modalButton.data("modal")) {
                const modalToOpenId = modalButton.data("modal");
                $("#" + modalToOpenId).addClass("js_active");
                htmlElement.css("overflow", "hidden");
            }
        }
    }

    function handleOverlayClick(event) {
        const overlay = $(event.currentTarget);
        const parentModal = overlay.parent(".modal.js_active");
        const htmlElement = $("html");

        if (parentModal.length) {
            parentModal.removeClass("js_active");
            htmlElement.css("overflow", "");
        }
    }

    function handleCloseClick(event) {
        const closeButton = $(event.currentTarget);
        const modalToCloseId = closeButton.data("close");
        const modalToClose = $("#" + modalToCloseId);
        const htmlElement = $("html");

        if (modalToClose.hasClass("js_active")) {
            modalToClose.removeClass("js_active");
            htmlElement.css("overflow", "");
        }
    }

    function handleEscapeKey(event) {
        const activeModal = $(".modal.js_active");
        const htmlElement = $("html");

        if ((event.key === "Escape" || event.key === "Esc" || event.keyCode === 27) && activeModal.length) {
            activeModal.removeClass("js_active");
            htmlElement.css("overflow", "");
        }
    }
};



$(document).ready(function () {
    btnFormNonValue();
    btnFormAddCart();
    uploadingProductData();
    editWishlist();
    editWishIconGlobal();
    submittingReviewAndFile();
    submittingFormsValidationCheck();
    modalInstructionsBehavior();
});

window.submittingReviewAndFile = submittingReviewAndFile;
window.editWishIconGlobal = editWishIconGlobal;