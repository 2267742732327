function getHeightTitleGoodBlockTiles(row = 'rows') {
    $('.js__goods_list .js_height_good_list .tile-good-title').css('min-height', '');
    if (row == 'rows' || $(window).width() < 768) {
        let row_length = 1;
        if (window.innerWidth >= 450 && window.innerWidth <= 1200) {
            row_length = 2
        } else if (window.innerWidth >= 1200) {
            row_length = 3
        }

        $('.js__goods_list').each(function () {
            let rows = {};
            let counter = 0;

            $(this).find('.js_height_good_list .tile-good-title').each(function (i) {
                if (rows[counter]) {
                    rows[counter] = [...rows[counter], $(this).height()];
                } else {
                    rows[counter] = [$(this).height()];
                }

                if ((i + 1) % row_length === 0) {
                    counter++;
                }
            })

            $(this).find('.js_height_good_list .tile-good-title').each(function (i) {
                const row_number = Math.floor(i / row_length);
                const row = rows[row_number].sort().reverse();

                $(this).css('min-height', `${row[0]}px`)
            })
        })
    }
}

function getHeightTitleGoodSigleTiles(row = 'rows') {
    $('.js__goods_list .js_height_good_list .tile-good-title').css('min-height', '');
    let row_length = 1;
    if (window.innerWidth >= 450 && window.innerWidth < 768) {
        row_length = 2
    } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
        row_length = 3
    } else if (window.innerWidth >= 992) {
        row_length = 4
    }

    $('.js__goods_list').each(function () {
        let rows = {};
        let counter = 0;

        $(this).find('.js_height_good_list .tile-good-title').each(function (i) {
            if (rows[counter]) {
                rows[counter] = [...rows[counter], $(this).height()];
            } else {
                rows[counter] = [$(this).height()];
            }

            if ((i + 1) % row_length === 0) {
                counter++;
            }
        })

        $(this).find('.js_height_good_list .tile-good-title').each(function (i) {
            const row_number = Math.floor(i / row_length);
            const row = rows[row_number].sort().reverse();

            $(this).css('min-height', `${row[0]}px`)
        })
    })
}

function getHeightTitleBlog() {
    $('.js__tile_blog .addition-name').css('min-height', '');

    let row_length = 2;
    if (window.innerWidth >= 768 && window.innerWidth < 1440) {
        row_length = 3
    } else if (window.innerWidth >= 1440) {
        row_length = 4
    }

    $('.js__tile_blog').each(function () {
        let rows = {};
        let counter = 0;

        $(this).find('.addition-name').each(function (i) {
            if (rows[counter]) {
                rows[counter] = [...rows[counter], $(this).height()];
            } else {
                rows[counter] = [$(this).height()];
            }

            if ((i + 1) % row_length === 0) {
                counter++;
            }
        })

        $(this).find('.addition-name').each(function (i) {
            const row_number = Math.floor(i / row_length);
            const row = rows[row_number].sort().reverse();

            $(this).css('min-height', `${row[0]}px`)
        })
    })
}

const alignmentHeightTileAddition = function () {

    $('.js_tile_good_list .tile-good-title').css('min-height', ``);
    $(".js_tile_good_list").each(function () {
        let maxHeight = 0;
        $(this).find('.tile-good-title').each(function () {
            if (maxHeight <= $(this).outerHeight()) {
                maxHeight = $(this).outerHeight();
            }
        });

        $(this).find('.tile-good-title').css('min-height', `${maxHeight}px`);
    });
}


const reviewsText = function () {
    $('.tile-good-review-true span, .good_info__reviews_count').each(function (e) {
        let decCache = [],
            decCases = [2, 0, 1, 1, 1, 2];

        const reviews = $(this).attr('data-reviews');
        const arrayTextReviews = [reviwsText.good_review_1, reviwsText.good_review_2, reviwsText.good_review_3];

        function decOfNum(number, titles) {
            if (!decCache[number]) decCache[number] = number % 100 > 4 && number % 100 <
                20 ? 2 :
                decCases[Math
                    .min(number % 10, 5)];
            return titles[decCache[number]];
        }

        $(this).text(`(${reviews} ${decOfNum(reviews, arrayTextReviews)})`)
    });
}

const globalTabs = function () {
    $('.js_tab_btn').on('click', function () {
        $('.js_tab_btn, .js_tabs_panel').removeClass('js_active_tab_viewed');
        $(this).addClass('js_active_tab_viewed');
        const tabName = $(this).attr('data-tabs');


        $('.js_tabs_panel').each(function () {
            if ($(this).attr('data-block') == tabName) {
                $(this).addClass('js_active_tab_viewed');

                if (!$(this).hasClass('slider_active')) {
                    $(this).slick({
                        slidesToShow: 5,
                        slidesToScroll: 1,
                        prevArrow: "<div class=\"swiper_button arrow-prev\"></div>",
                        nextArrow: "<div class=\"swiper_button arrow-next\"></div>",
                        rows: 0,
                        responsive: [{
                            breakpoint: 1366,
                            settings: {
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        }, {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        }, {
                            breakpoint: 768,
                            settings: {
                                dots: true,
                                customPaging: function (slider, i) {
                                    return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                                },
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        }, {
                            breakpoint: 450,
                            settings: {
                                dots: true,
                                customPaging: function (slider, i) {
                                    return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                                },
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }]
                    });
                }
                $(this).addClass('slider_active');
                alignmentHeightTileAddition();
            }
        });
    });

    $('.js_tab_btn').each(function (i) {
        if (i == 0) {
            $(this).trigger('click');
            alignmentHeightTileAddition();
        }
    });
}

const brendHeight = function () {
    $('.addition-brand-content').each(function () {
        $(this).find('.addition-brand-item a').css('min-height', '')
        let heightBig = 0;

        $(this).find('.addition-brand-item a').each(function () {
            if (heightBig < $(this).outerHeight()) {
                heightBig = $(this).outerHeight();
            }
        });

        $(this).find('.addition-brand-item a').css('min-height', heightBig)
    });
}

$(window).on('load', function () {
    reviewsText();
    alignmentHeightTileAddition();
    globalTabs();

    if ($('.js__tile_blog').length > 0) {
        getHeightTitleBlog();
    }
});

$(window).resize(function () {
    alignmentHeightTileAddition();
    brendHeight();

    if ($('.js__tile_blog').length > 0) {
        getHeightTitleBlog();
    }
});

$(document).ready(function () {
    brendHeight();


    $('.js__view_slider').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: "<div class=\"swiper_button arrow-prev\"></div>",
        nextArrow: "<div class=\"swiper_button arrow-next\"></div>",
        rows: 0,
        responsive: [{
            breakpoint: 1366,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        }, {
            breakpoint: 768,
            settings: {
                dots: true,
                customPaging: function (slider, i) {
                    return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                },
                slidesToShow: 2,
                slidesToScroll: 2
            }
        }, {
            breakpoint: 450,
            settings: {
                dots: true,
                customPaging: function (slider, i) {
                    return `<button type="button" class="swiper_dots" role="tab" aria-selected="true"></button>`;
                },
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    });
})

window.getHeightTitleGoodBlockTiles = getHeightTitleGoodBlockTiles;
window.reviewsText = reviewsText;
window.globalTabs = globalTabs;
window.getHeightTitleGoodSigleTiles = getHeightTitleGoodSigleTiles;