const videoPosterHide = function () {
    $('.video').on('click', function () {
        if ($(this).children('.js_poster').length != 0) {
            $(this).children('.js_poster').addClass('js_hide');
            if ($(this).children('.js_video_src').length != 0) {
                $(this).children('.js_video_src')[0].play();
            }
        }
    });
}

window.videoPosterHide = videoPosterHide;